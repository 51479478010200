import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { LangSwitcher } from "./components/LangSwitcher";
import DiplomaPage from "./pages/DiplomaPage";
import ErrorDiplomaPage from "./pages/ErrorDiplomaPage";
import HomePage from "./pages/HomePage";

function App() {
  return (
    <div>
      <LangSwitcher />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/diploma" element={<DiplomaPage />} />
        <Route path="/diploma_not_found" element={<ErrorDiplomaPage />} />
      </Routes>
    </div>
  );
}

export default App;

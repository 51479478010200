import { useTranslation } from "react-i18next";

const ErrorDiplomaPage = () => {
  const { t } = useTranslation();
  return (
    <div className="flex min-h-full mt-20 flex-col justify-center px-6 lg:px-8">
      <div className="flex justify-center items-center w-full mt-10 gap-3">
        <img src="/error_icon.svg" width="25px" height="25px" />
        <h4 className="text-red-400">{t("error_page.not_found")}</h4>
      </div>
      <div className="mt-10 md:mx-auto md:w-full md:max-w-md">
        <div className="space-y-3">
          <h3 className="text-center block text-md font-medium leading-9 text-gray-300">
            {t("error_page.check_data")}
          </h3>
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {t("error_page.retry")}
            </button>
          </div>
          <h4 className="text-center text-sm font-sm mt-5 text-gray-500">
            {t("error_page.smth_wrong")}
          </h4>
          <a
            className="text-center block text-md font-md  text-white"
            href="mailto:tattoo.association.com@gmail.com"
          >
            tattoo.association.com@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default ErrorDiplomaPage;

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

const INPUTS = [
  { name: "first_name", label: "succes_page.name", type: "text" },
  { name: "last_name", label: "succes_page.surname", type: "text" },
  { name: "diploma", label: "succes_page.dip_num", type: "text" },
  { name: "parlor_name", label: "succes_page.salon", type: "text" },
  { name: "start_training", label: "succes_page.start_date", type: "text" },
  { name: "finished_at", label: "succes_page.end_date", type: "text" },
  { name: "mentor_name", label: "succes_page.mentor", type: "text" },
  { name: "test", label: "succes_page.main_work", type: "photo" },
  { name: "photo", label: "succes_page.diploma", type: "photo" },
];

const DiplomaPage = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  
  return (
    <>
      <div className="flex justify-center items-center w-full mt-10 gap-3">
        <img
          src="/success_icon.svg"
          width="25px"
          height="25px"
          alt="Success Icon"
        />
        <h4 className="text-green-400">{t("succes_page.success")}</h4>
      </div>
      <div className="mt-10">
        {INPUTS.map((input) => (
          <div
            key={input.name}
            className="flex flex-col mx-auto p-2 max-w-screen-sm"
          >
            <label
              htmlFor={input.name}
              className="block text-sm font-medium leading-6 text-gray-300"
            >
              {t(input.label)}
            </label>
            <div className="mt-2 flex justify-center">
              {input.type === "text" && (
                <input
                  id={input.name}
                  name={input.name}
                  type="text"
                  value={state[input.name]}
                  disabled
                  className="block bg-gray-500 bg-opacity-40 w-full pl-2 rounded-md border-0 py-1.5 text-white sm:text-sm sm:leading-6"
                />
              )}
              {input.type === "photo" && (
                <>
                  <img
                    src={`${state[input.name]}`}
                    className="rounded-md border-2 border-gray-300"
                    alt="Photo"
                  />
                </>
              )}
            </div>
          </div>
        ))}
        {/* <button className="flex justify-center mx-auto mt-3 rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">
          {t("succes_page.download_pdf")}
        </button> */}
      </div>
    </>
  );
};

export default DiplomaPage;

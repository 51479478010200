const IMAGES = [
  { src: "/partner.svg", url: "https://tattoo-association.com/" },
  { src: "/partner_2.svg", url: "https://art-ink-corp.com/" },
  { src: "/partner_3.svg", url: "https://www.global-tattoo.com/" },
  { src: "/partner_4.svg", url: "https://association-tattoo.com/" },
  { src: "/partner_5.svg", url: "https://tattoo.ua/" },
  { src: "/szkola_logo.svg" },
  { src: "/msmt.png", url: "https://www.msmt.cz/" },
];

export const PartnersIcons = () => {
  return (
    <div className="flex justify-around mb-3">
      {IMAGES.map((el) => (
        <a href={el.url} target="_blank" rel="noreferrer">
          <img className="w-40 h-32" src={el.src} alt="img" />
        </a>
      ))}
    </div>
  );
};

import axios from "axios";

export const promisePl = ({ diploma_number, email }) =>
  axios.post("https://cr.vean-tattoo.pl/api/v1/check-diploma/", {
    diploma_number,
    email,
  });
export const promiseUa = ({ diploma_number, email }) =>
  axios.post("https://crm.vean-tattoo.com/api/v1/check-diploma/", {
    diploma_number,
    email,
  });
export const promiseCz = ({ diploma_number, email }) =>
  axios.post("https://crm.vean-tattoo.cz/api/v1/check-diploma/", {
    diploma_number,
    email,
  });
export const promiseHu = ({ diploma_number, email }) =>
  axios.post("https://crm.vean-tattoo.hu/api/v1/check-diploma/", {
    diploma_number,
    email,
  });
export const promiseLt = ({ diploma_number, email }) =>
  axios.post("https://crm.vean-tattoo.lt/api/v1/check-diploma/", {
    diploma_number,
    email,
  });
export const promiseEs = ({ diploma_number, email }) =>
  axios.post("https://crm.vean-tattoo.es/api/v1/check-diploma/", {
    diploma_number,
    email,
  });
export const promiseNl = ({ diploma_number, email }) =>
  axios.post("https://crm.vean-tattoo.nl/api/v1/check-diploma/", {
    diploma_number,
    email,
  });
export const promiseBg = ({ diploma_number, email }) =>
  axios.post("https://crm.vean-tattoo.bg/api/v1/check-diploma/", {
    diploma_number,
    email,
  });
export const promiseLv = ({ diploma_number, email }) =>
  axios.post("https://crm.vean-tattoo.lv/api/v1/check-diploma/", {
    diploma_number,
    email,
  });
export const promiseSk = ({ diploma_number, email }) =>
  axios.post("https://crm.vean-tattoo.sk/api/v1/check-diploma/", {
    diploma_number,
    email,
  });
export const promiseDe = ({ diploma_number, email }) =>
  axios.post("https://crm.vean-tattoo.de/api/v1/check-diploma/", {
    diploma_number,
    email,
  });
export const promiseUk = ({ diploma_number, email }) =>
  axios.post("https://crm.vean-tattoo.uk/api/v1/check-diploma/", {
    diploma_number,
    email,
  });
export const promiseCa = ({ diploma_number, email }) =>
  axios.post("https://crm.vean-tattoo.ca/api/v1/check-diploma/", {
    diploma_number,
    email,
  });

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  promiseBg,
  promiseCa,
  promiseCz,
  promiseDe,
  promiseEs,
  promiseHu,
  promiseLt,
  promiseLv,
  promiseNl,
  promisePl,
  promiseSk,
  promiseUa,
  promiseUk,
} from "../helpers/diplomaPromises";

const HomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData(e.target);
      const diploma_number = formData.get("diploma_number");
      const email = formData.get("email");
      const response = await Promise.any([
        promiseBg({ diploma_number, email }),
        promiseCa({ diploma_number, email }),
        promiseCz({ diploma_number, email }),
        promiseDe({ diploma_number, email }),
        promiseEs({ diploma_number, email }),
        promiseHu({ diploma_number, email }),
        promiseLt({ diploma_number, email }),
        promiseLv({ diploma_number, email }),
        promiseNl({ diploma_number, email }),
        promisePl({ diploma_number, email }),
        promiseSk({ diploma_number, email }),
        promiseUa({ diploma_number, email }),
        promiseUk({ diploma_number, email }),
      ]);
      navigate("/diploma", { state: response.data });
    } catch (error) {
      navigate("/diploma_not_found");
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            {t("main.title")}
          </h2>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <div className="mt-2">
                <label
                  for="text"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  * {t("main.dip_num")}
                  <input
                    id="text"
                    name="diploma_number"
                    type="text"
                    required
                    className="block bg-gray-500 bg-opacity-40 w-full pl-2 rounded-md border-0 py-1.5 text-white focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-transparent  sm:text-sm sm:leading-6"
                  />
                </label>
              </div>
              <div className="mt-2">
                <label
                  for="text"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  * Email
                  <input
                    id="text"
                    name="email"
                    type="text"
                    required
                    className="block bg-gray-500 bg-opacity-40 w-full pl-2 rounded-md border-0 py-1.5 text-white focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-transparent  sm:text-sm sm:leading-6"
                  />
                </label>
              </div>
            </div>
            <div>
              <button
                type={"submit"}
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {t("main.send")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default HomePage;
